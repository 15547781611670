<template>
  <div class="card" id="mycard">
    <div id="toolbar" class="fixed-table-toolbar">
      <div class="float-left">
        <h5 style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px;"> <span class="icon-list"></span> &nbsp; Work Order Status</h5>
      </div>
      <div class="float-none">


      </div>
<!--      <div class="float-right search">-->
<!--        <input  id="txtsearch" class="form-control" type="text" placeholder="Search" autocomplete="off">-->
<!--      </div>-->

    </div>

    <div class="row">
      <div class="col-lg-4">
        <div class="form-group form-group-material">
          <div class="form-group row">
            <label class="col-md-3 col-form-label">Process:</label>
            <div class="col-md-9">
              <select ref="el_process" class="form-control select" autofocus="" v-model="process_id"  @change="loadOrders(process_id, jobworker_id)">
                <option value="305" selected>Weaving</option>
                <option value="350">Knitting</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4">

        <div class="form-group form-group-material">
          <div class="form-group row">
            <label class="col-md-3 col-form-label">Jobworker:</label>
            <div class="col-md-9">
              <select id="cmbjobworker" class="form-control select" v-model="jobworker_id" @change="loadOrders(process_id, jobworker_id)">
                <option value="0"selected>-</option>
                <option v-for="ledger in ledgers" v-bind:value="ledger.id">
                  {{ ledger.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="form-group form-group-material">
          <div class="form-group row">
            <label class="col-md-3 col-form-label">Work Order:</label>
            <div class="col-md-9">
              <select class="form-control" v-model="workorder.id" @change="loadData(workorder.id)" >
                <option value="1"selected>-</option>
                <option v-for="order in orders" v-bind:value="order.id">
                  {{order.doc_no}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive" >
      <table id="order_status"
             class="table table-borderless table-columned table-hover"
             data-search="false"
             data-pagination="true"
             data-show-refresh="false"
             data-show-columns="false"
             data-trim-on-search="true"
             data-page-list="[ALL, 10, 25, 50, 100]"
             data-show-footer="true"
             data-toggle="context"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="id" data-class="d-none">id</th>
          <th data-formatter="runningFormatter" data-width="75" >S.No</th>
          <!--<th data-field="item.code" data-width="75" data-sortable="true">Code</th>-->
          <th data-field="itm.quality.name" data-sortable="true">Quality</th>
          <th data-field="itm.color.name" data-sortable="true" >Color</th>
          <th data-field="debit" data-width="80" data-formatter="indianFormat4Value" data-align="right" data-sortable="true">Beams</th>
          <th data-field="dr_wgt" data-width="100" data-formatter="indianFormat4Value" data-align="right" data-sortable="true">Yarn Weight</th>
          <th data-field="pcs" data-width="100" data-formatter="indianFormat4Value" data-align="right" data-sortable="true">Pcs</th>
          <th data-field="credit" data-width="100" data-formatter="indianFormat4Value" data-align="right" data-sortable="true">Mtrs</th>
          <th data-field="cr_wgt" data-width="100" data-formatter="weightFormat4Value" data-align="right" data-sortable="true">Fab Weight</th>
          <!--<th data-field="taxable_amt" data-width="100" data-formatter="indianFormat" data-align="right" data-sortable="true">Value</th>-->
        </tr>
        </thead>
        <tbody>

        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData(workorder.id)"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" style="color: darkgreen;"> <i class="icon-file-excel" ></i>Export</a>
      </div>
    </div>
    <!-- End of Context Menu -->



  </div>

</template>

<script>
  import InvoiceForm from '@/views/tms/vouchers/invoice/InvoiceForm.vue'
  import FabButton from '@/components/core/FabButton.vue'
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'
  export default {
    name: 'WorkOrderStatusView',
    components: {
      InvoiceForm,
      FabButton
    },
    store,
    data () {
      return {
        mytable: {},
        processes: [],
        ledgers:[],
        orders:[],
        process_id :0,
        jobworker_id : 0,
        process:{"id":0,"name":""},
        ledger:{"id":0,"name":""},
        workorder:{"id":0,"name":""},
      }
    },
    created () {

    },
    mounted () {
      const self = this;

      this.$data.mytable = $('#order_status');
      this.$data.mytable.bootstrapTable({
        stickyHeader: true
      });
      $('[data-toggle="popover"]').popover();

      this.$data.mytable.contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {
          var id = $(row.children('*')[0]).text();
          if ($(e.target).text() === 'Modify') {

          }else if ($(e.target).text() === 'Delete') {

          }
        }
      });

      $('#txtsearch').keyup(function () {
        var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
        $('#order_status>tbody>tr').show().filter(function () {
          var text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
          return !~text.indexOf(val);
        }).hide();
      });

      self.loadJobworkers();

      if(self.jobworker_id > 0 && self.process_id > 0 ) {
        self.loadOrders(self.process_id, self.jobworker_id);
      }

      self.$refs.el_process.focus();

    },
    methods: {
      idFormatter () {
        return 'Total'
      },
      footerStyle (value, row, index) {
        return { css: { "font-weight": "bold" } }
      },
      loadJobworkers () {
        const self = this;

        const requestOptions = {
          mode: 'cors',
          headers: userService.authHeader()
        };

        $('#cmbjobworker').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        //fetch the Ledgers
        self.$data.ledgers = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/jobworkers`, requestOptions).then(userService.handleResponse).then(function (resp) {
          $('#cmbjobworker').unblock();
          if (resp.ok) {
            if (resp.data != null) {
              self.$data.ledgers = resp.data;
            }
          } else {
            swal({ title: "Oops", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {
          swal({ title: "Oops", text: err.toString(), type: "error" });
        }).finally(function () {
          //$('#cmbjobworker').unblock();
        });

      },
      loadOrders (processid, jobworkerid) {

        const self = this;
        const requestOptions = {
          mode: 'cors',
          headers: userService.authHeader()
        };

        self.$data.orders = [];
        if (processid > 0 && jobworkerid > 0) {

          $('#mycard').block({
            message: '<i class="icon-spinner2 spinner"></i>',
            overlayCSS: {
              backgroundColor: '#fff',
              opacity: 0.8,
              cursor: 'wait',
              'box-shadow': '0 0 0 1px #ddd'
            },
            css: {
              border: 0,
              padding: 0,
              backgroundColor: 'none'
            }
          });

          // alert(`${process.env.VUE_APP_ROOT_API}api/workorders/${processid}/${jobworkerid}`);
          fetch(`${process.env.VUE_APP_ROOT_API}api/workorders/${processid}/${jobworkerid}`, requestOptions).then(userService.handleResponse).then(function (resp) {
            $('#mycard').unblock();
            if (resp.ok) {

              if (Array.isArray(resp.data)) {
                self.$data.orders = resp.data;
              }

            } else {
              swal({ title: "Oops", text: resp.msg, type: "error" });
            }
          }).catch(function (err) {
            $('#mycard').unblock();
            swal({ title: "Oh noes", text: err.toString(), type: "error" });
          });
        }

      },
      loadData(id){

      },
    }
  }
</script>

<style scoped>

</style>
